export default {
  message: {
    Wallet_address: 'Wallet Address',
    Log_out: 'Log Out',
    contract: 'Contract',
    Authorized_token: 'Approve token',
    Authorized_limit: 'Approve limit',
    Change_quota: 'Revoke',
    unlimited: 'unlimited',
    confirm_title: 'Approval limit modification',
    confirm_content: 'Confirm to change the approval quota of the current contract to 0?',
    confirm_content2: 'Confirm to change the approval quota of the current contract to 0?',
    confirm: 'Confirm',
    cancle: 'Cancel',
    revoke: 'Revoke',
    token: 'Token',
    address: 'Address',
    limit: 'Limit',
    approval: 'Approval',
    unapproval: 'Unapproval',
    action: 'Action',
    select_wallet: 'Please select wallet',
    time: 'Date',
    type: 'Type',
    new22: 'Token',
    new23: 'Token',
    new24: 'Token Address',
    new25: 'Allowance',
    new26: 'Contract',
  },
}
