export default {
  message: {
    Wallet_address: '钱包地址',
    Log_out: '登出',
    contract: '合约',
    Authorized_token: '授权代币',
    Authorized_limit: '授权额度',
    Change_quota: '额度修复',
    unlimited: '无限',
    confirm_title: '授权额度修改',
    confirm_content: '确认将当前合约授权额度修改为0？',
    confirm_content2: '确认将当前合约授权额度修改为0？',
    confirm: '确认',
    cancle: '取消',
    revoke: '确定',
    token: '代币',
    address: '地址',
    limit: '限制',
    approval: '授权',
    unapproval: '未授权',
    action: '操作',
    select_wallet: '请选择钱包',
    time: '时间',
    type: '类别',
    new22: '授权代币',
    new23: '代币',
    new24: '代币地址',
    new25: '限制',
    new26: '授权',
  },
}
