// let localStorage = window.localStorage
import Cookies from 'js-cookie'
let domain = window.location.hostname
let convertDomain = checkAddress(domain)

function checkAddress(address) {
  const ipRegex = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/

  if (ipRegex.test(address)) {
    return address
  } else if (domainRegex.test(address)) {
    return convertToTopLevelDomain(address)
  } else {
    return address
  }
}

function convertToTopLevelDomain(domain) {
  const topLevelDomainRegex = /\.(.*?)$/ // 匹配最后一个点后的内容

  const matches = domain.match(topLevelDomainRegex) // 匹配结果数组

  if (matches && matches.length > 0) {
    return matches[0]
  }

  return domain
}

const setLoginState = (store, para) => {
  if (!para) {
    // localStorage.removeItem('userToken')
    Cookies.remove('address', { domain: convertDomain })
    Cookies.remove('extension', { domain: convertDomain })
    store.commit('setAddress', null)
    store.commit('setExtension', null)
  } else {
    // localStorage.setItem('userToken', JSON.stringify(para))
    Cookies.set('address', para.address, { domain: convertDomain })
    Cookies.set('extension', para.extension, { domain: convertDomain })
    store.commit('setAddress', para.address)
    store.commit('setExtension', para.extension)
  }
}

const firstLoading = (store) => {
  // let localstorage = localStorage.getItem('userToken')
  // if (localstorage) {
  //   let temp = JSON.parse(localstorage)
  //   setLoginState(store, temp)
  // }

  let address = Cookies.get('address')
  let extension = Cookies.get('extension')
  if (address && extension) {
    setLoginState(store, { address, extension })
  }
}

export { setLoginState, firstLoading }
